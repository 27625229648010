import ReactDOM from "react-dom/client";
import App from "./App";

import { DefaultFarmStackProvider } from "common/components/context/DefaultContext/FarmstackProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <DefaultFarmStackProvider>
    <App />
  </DefaultFarmStackProvider>
);
