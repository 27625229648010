// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accountsettingsupload {
  background: #ffffff;
  border: 1px dashed #d8af28;
  border-radius: 2px;
  margin-top: 8%;
  margin-left: 27%;
  width: 240%;
  height: 218px;
}

.accountsettingsheader {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #3d4a52;
  padding-top: 2%;
}
`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/Settings/accounts/UploadProfileimg.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,0BAA0B;EAC1B,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB","sourcesContent":[".accountsettingsupload {\n  background: #ffffff;\n  border: 1px dashed #d8af28;\n  border-radius: 2px;\n  margin-top: 8%;\n  margin-left: 27%;\n  width: 240%;\n  height: 218px;\n}\n\n.accountsettingsheader {\n  font-family: \"Open Sans\";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 19px;\n  text-align: center;\n  color: #3d4a52;\n  padding-top: 2%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
