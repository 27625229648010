// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mr-16 {
  margin-right: 16px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.ml-173 {
  margin-left: 173px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}
.datapoint-category-classname > div:nth-child(1) {
  cursor: default !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/Datasets_New/TabComponents/Standardise.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,8BAA8B;AAChC;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".mr-16 {\n  margin-right: 16px !important;\n}\n\n.mr-120 {\n  margin-right: 120px !important;\n}\n\n.ml-173 {\n  margin-left: 173px !important;\n}\n\n.ml-16 {\n  margin-left: 16px !important;\n}\n\n.mb-26 {\n  margin-bottom: 26px !important;\n}\n.datapoint-category-classname > div:nth-child(1) {\n  cursor: default !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
