// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file_table_column {
    font-family: 'Open Sans' !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22.19px !important;
    color: #3D4A52 !important;
}

.file_table_row {
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 19.42px !important;
    color: #3D4A52 !important;
}

.file_table_column_bg {
    background: #F8F8F8 !important;
}

.file_table_row_bg {
    background: #ffffff !important;
}

.b-1 {
    border: 1px solid #E4E4E4;
}

.b_right {
    border-right: 1px solid rgba(238, 238, 238, 0.5);
}

.b_left {
    border-left: 1px solid rgba(238, 238, 238, 0.5);
}

.p-10 {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.w-100vw {
    width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/Datasets_New/FileTable.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,2BAA2B;IAC3B,0BAA0B;IAC1B,+BAA+B;IAC/B,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;IAChC,2BAA2B;IAC3B,0BAA0B;IAC1B,+BAA+B;IAC/B,yBAAyB;AAC7B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gDAAgD;AACpD;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".file_table_column {\n    font-family: 'Open Sans' !important;\n    font-weight: 600 !important;\n    font-size: 16px !important;\n    line-height: 22.19px !important;\n    color: #3D4A52 !important;\n}\n\n.file_table_row {\n    font-family: 'Roboto' !important;\n    font-weight: 400 !important;\n    font-size: 14px !important;\n    line-height: 19.42px !important;\n    color: #3D4A52 !important;\n}\n\n.file_table_column_bg {\n    background: #F8F8F8 !important;\n}\n\n.file_table_row_bg {\n    background: #ffffff !important;\n}\n\n.b-1 {\n    border: 1px solid #E4E4E4;\n}\n\n.b_right {\n    border-right: 1px solid rgba(238, 238, 238, 0.5);\n}\n\n.b_left {\n    border-left: 1px solid rgba(238, 238, 238, 0.5);\n}\n\n.p-10 {\n    padding-left: 10px;\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n.w-100vw {\n    width: 100vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
