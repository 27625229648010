// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datasets_list_view_title {
  font-family: "Arial" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.datasets_list_view_text {
  font-family: "Arial" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #000000 !important;
}

.datasets_list_view_name {
  white-space: nowrap;
  width: 183px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.datasets_list_view_details_ellipsis {
  white-space: nowrap;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mr-44 {
  margin-right: 44px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.green_text {
  color: #00a94f !important;
}
.mt114 {
  margin-top: 114px;
}

.mt10 {
  margin-top: 10px !important;
}

.mb139 {
  margin-bottom: 139px;
}
`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/Datasets_New/DataSetsListView.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;EAC/B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".datasets_list_view_title {\n  font-family: \"Arial\" !important;\n  font-weight: 600 !important;\n  font-size: 16px !important;\n  line-height: 24px !important;\n  color: #000000 !important;\n}\n\n.datasets_list_view_text {\n  font-family: \"Arial\" !important;\n  font-weight: 400 !important;\n  font-size: 16px !important;\n  line-height: 22px !important;\n  color: #000000 !important;\n}\n\n.datasets_list_view_name {\n  white-space: nowrap;\n  width: 183px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.datasets_list_view_details_ellipsis {\n  white-space: nowrap;\n  width: 170px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.mr-44 {\n  margin-right: 44px !important;\n}\n\n.ml-90 {\n  margin-left: 90px !important;\n}\n\n.green_text {\n  color: #00a94f !important;\n}\n.mt114 {\n  margin-top: 114px;\n}\n\n.mt10 {\n  margin-top: 10px !important;\n}\n\n.mb139 {\n  margin-bottom: 139px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
