// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datasetfiltertext{
width: 35px;
height: 19px;
left: 193px;
top: 136px;

margin-left: 5px;
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
/* identical to box height */

align-items: center;

color: #3A3A3A;
}
.filterClearAll{

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */
display: flex;
align-items: center;
text-decoration-line: underline;

color: #3491EE;
}`, "",{"version":3,"sources":["webpack://./src/features/default/src/Views/Dataset/DatasetFilter.css"],"names":[],"mappings":"AAAA;AACA,WAAW;AACX,YAAY;AACZ,WAAW;AACX,UAAU;;AAEV,gBAAgB;AAChB,wBAAwB;AACxB,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,4BAA4B;;AAE5B,mBAAmB;;AAEnB,cAAc;AACd;AACA;;AAEA,wBAAwB;AACxB,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,4BAA4B;AAC5B,aAAa;AACb,mBAAmB;AACnB,+BAA+B;;AAE/B,cAAc;AACd","sourcesContent":[".datasetfiltertext{\nwidth: 35px;\nheight: 19px;\nleft: 193px;\ntop: 136px;\n\nmargin-left: 5px;\nfont-family: 'Open Sans';\nfont-style: normal;\nfont-weight: 600;\nfont-size: 14px;\nline-height: 19px;\n/* identical to box height */\n\nalign-items: center;\n\ncolor: #3A3A3A;\n}\n.filterClearAll{\n\nfont-family: 'Open Sans';\nfont-style: normal;\nfont-weight: 400;\nfont-size: 14px;\nline-height: 19px;\n/* identical to box height */\ndisplay: flex;\nalign-items: center;\ntext-decoration-line: underline;\n\ncolor: #3491EE;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
