// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projectform .connectormainheading {
  color: #3d4a52;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  margin-top: 8% !important;
  text-align: start;
  margin-left: 6%;
}
.projectform .supportViewDetailsbackimage {
  margin-left: 14px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Open Sans";
  color: #3491ee;
  margin-top: 0px;
  margin-left: 7%;
}
.projectform .department {
  padding-left: 8%;
  margin-top: 2%;
  text-align: left;
}
.projectform .project {
  /* margin-top: 2%; */
  text-align: left;
  margin-top: 2.7%;
}
.projectform .projectName {
  width: 80%;
}

.projectform .description {
  float: left;
  /* width: 80% !important; */
  margin-top: 2%;
  /* padding-left: 2% !important; */
  text-align: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 7%;
}
.projectform .descriptionName {
  width: 83.5% !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/Settings/Participants/Project/ProjectForm.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,wBAAwB;EACxB,cAAc;EACd,eAAe;EACf,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,2BAA2B;EAC3B,cAAc;EACd,iCAAiC;EACjC,gBAAgB;EAChB,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".projectform .connectormainheading {\n  color: #3d4a52;\n  font-family: \"Open Sans\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 20px;\n  line-height: 27px;\n  margin-top: 8% !important;\n  text-align: start;\n  margin-left: 6%;\n}\n.projectform .supportViewDetailsbackimage {\n  margin-left: 14px;\n  font-weight: 400;\n  font-size: 14px;\n  font-family: \"Open Sans\";\n  color: #3491ee;\n  margin-top: 0px;\n  margin-left: 7%;\n}\n.projectform .department {\n  padding-left: 8%;\n  margin-top: 2%;\n  text-align: left;\n}\n.projectform .project {\n  /* margin-top: 2%; */\n  text-align: left;\n  margin-top: 2.7%;\n}\n.projectform .projectName {\n  width: 80%;\n}\n\n.projectform .description {\n  float: left;\n  /* width: 80% !important; */\n  margin-top: 2%;\n  /* padding-left: 2% !important; */\n  text-align: left;\n  font-family: \"Open Sans\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  margin-left: 7%;\n}\n.projectform .descriptionName {\n  width: 83.5% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
