// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browse_style {
    width: 468px;
}
.imports_style{
    max-width: fit-content;
    margin-left: 15px;
}

.list_files {
    width: 370px;
}

.list_upload_style {
    margin-left: 30px;
    max-height: 500px;
    overflow: auto; 
}`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/Datasets_New/TabComponents/UploadFile.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".browse_style {\n    width: 468px;\n}\n.imports_style{\n    max-width: fit-content;\n    margin-left: 15px;\n}\n\n.list_files {\n    width: 370px;\n}\n\n.list_upload_style {\n    margin-left: 30px;\n    max-height: 500px;\n    overflow: auto; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
