// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS */
.button-87 {
    /* margin: 10px; */
    padding: 10px 30px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: #534104;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #d69437e9;
    background-image: linear-gradient(45deg, #FFDB64 0%, #d69437e9 51%, #FFDB64 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    text-transform: capitalize;
    width: 200px;
    height: 50px;
    font-size: 16px;
}

.button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.button-87:active {
    transform: scale(0.95);
}`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/IntegrationConnectors/cards.css"],"names":[],"mappings":"AAAA,QAAQ;AACR;IACI,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,0BAA0B;IAC1B,cAAc;IACd,mBAAmB;IACnB,cAAc;IACd,WAAW;IACX,gBAAgB;IAChB,uCAAuC;IACvC,iFAAiF;IACjF,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,0BAA0B;IAC1B,0BAA0B;IAC1B,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,iCAAiC;IACjC,4CAA4C;IAC5C,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["/* CSS */\n.button-87 {\n    /* margin: 10px; */\n    padding: 10px 30px;\n    text-align: center;\n    transition: 0.5s;\n    background-size: 200% auto;\n    color: #534104;\n    border-radius: 10px;\n    display: block;\n    border: 0px;\n    font-weight: 700;\n    box-shadow: 0px 0px 14px -7px #d69437e9;\n    background-image: linear-gradient(45deg, #FFDB64 0%, #d69437e9 51%, #FFDB64 100%);\n    cursor: pointer;\n    user-select: none;\n    -webkit-user-select: none;\n    touch-action: manipulation;\n    text-transform: capitalize;\n    width: 200px;\n    height: 50px;\n    font-size: 16px;\n}\n\n.button-87:hover {\n    background-position: right center;\n    /* change the direction of the change here */\n    color: #fff;\n    text-decoration: none;\n}\n\n.button-87:active {\n    transform: scale(0.95);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
