// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    text-align: center;
    background-color: #C09507;
    /*position: fixed;*/
    bottom: 0px;
    min-width: 1440px;
    min-height: 80px;
    vertical-align: middle;
    padding-top: 30px;
    margin: 0px;
}

.footerlink {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    /*margin-left: 100px;*/
}

.footerlink:hover {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.footerlink .footerlinkdisabled {
    pointer-events: none;
}

.footer .row {
    margin: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,sBAAsB;IACtB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".footer {\n    text-align: center;\n    background-color: #C09507;\n    /*position: fixed;*/\n    bottom: 0px;\n    min-width: 1440px;\n    min-height: 80px;\n    vertical-align: middle;\n    padding-top: 30px;\n    margin: 0px;\n}\n\n.footerlink {\n    font-family: 'Open Sans';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 19px;\n    color: #FFFFFF;\n    /*margin-left: 100px;*/\n}\n\n.footerlink:hover {\n    color: white;\n    text-decoration: none;\n    cursor: pointer;\n}\n\n.footerlink .footerlinkdisabled {\n    pointer-events: none;\n}\n\n.footer .row {\n    margin: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
