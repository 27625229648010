// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container{
    margin-top: 50px;
    margin-left: 180px;
    margin-right: 180px;
}

.banner{
    height: 320px;
    width: 100%;
    min-width: 1440px;
    height: 320px;
}

.logo{
    width: 200px;
    height: 200px;

    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(216, 175, 40, 0.13);
    border-radius: 120px;

    min-width: "140px";
    min-height: "140px"; 
    max-width : "140px";
    max-height: "140px";
    margin-top: "-70px";
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/GuestUser/GuestUserBanner.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;;IAEb,mBAAmB;IACnB,iDAAiD;IACjD,oBAAoB;;IAEpB,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".container{\n    margin-top: 50px;\n    margin-left: 180px;\n    margin-right: 180px;\n}\n\n.banner{\n    height: 320px;\n    width: 100%;\n    min-width: 1440px;\n    height: 320px;\n}\n\n.logo{\n    width: 200px;\n    height: 200px;\n\n    background: #FFFFFF;\n    box-shadow: 0px 4px 16px rgba(216, 175, 40, 0.13);\n    border-radius: 120px;\n\n    min-width: \"140px\";\n    min-height: \"140px\"; \n    max-width : \"140px\";\n    max-height: \"140px\";\n    margin-top: \"-70px\";\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
