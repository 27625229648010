// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dataset-card-header :nth-child(2){
    display: flex;
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/Participants/participantsCards.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":["\n.dataset-card-header :nth-child(2){\n    display: flex;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
