// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url("https://fonts.googleapis.com/css2?family=Arial:wght@300;400;600;700&display=swap"); */

.add_light_text {
  font-family: "Arial";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #212b36;
}

.tab_header {
  font-family: "Arial";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #637381;
  text-transform: none;
}

.tab_header_selected {
  font-family: "Arial";
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #00a94f;
  text-transform: none;
}

.ml-16 {
  margin-left: 16px;
}

.mt-50 {
  margin-top: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/Datasets_New/AddDataSet.css"],"names":[],"mappings":"AAAA,qGAAqG;;AAErG;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/* @import url(\"https://fonts.googleapis.com/css2?family=Arial:wght@300;400;600;700&display=swap\"); */\n\n.add_light_text {\n  font-family: \"Arial\";\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n  color: #212b36;\n}\n\n.tab_header {\n  font-family: \"Arial\";\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 22px;\n  color: #637381;\n  text-transform: none;\n}\n\n.tab_header_selected {\n  font-family: \"Arial\";\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 22px;\n  color: #00a94f;\n  text-transform: none;\n}\n\n.ml-16 {\n  margin-left: 16px;\n}\n\n.mt-50 {\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
