// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loding-img-containe{
    width: 100%;
    height: 80%;
}

.loding-img-div{
    margin: 15% auto;
}
.loding-img-div > img{
    height: 150px;
}
.loding-img-div > h1{
    color: #050504cc;
    font-size: 22px;
    margin-top: 40px;
    font-weight: 400;
}
.loding-img-div > h3{
    color: #050504cc;
    font-size: 18px;
    /* margin-top: 40px; */
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/GuestUser/noDatasetGuestUser.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".loding-img-containe{\n    width: 100%;\n    height: 80%;\n}\n\n.loding-img-div{\n    margin: 15% auto;\n}\n.loding-img-div > img{\n    height: 150px;\n}\n.loding-img-div > h1{\n    color: #050504cc;\n    font-size: 22px;\n    margin-top: 40px;\n    font-weight: 400;\n}\n.loding-img-div > h3{\n    color: #050504cc;\n    font-size: 18px;\n    /* margin-top: 40px; */\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
