// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NotFound.css */
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f0f2f5; /* Light grey background */
}

.not-found-content {
  text-align: center;
}

.not-found-content h1 {
  font-size: 72px;
  color: #505f79; /* Dark grey color for the '404' text */
}

.not-found-content h2 {
  font-size: 24px;
  color: #505f79;
}

.not-found-content p {
  color: #505f79;
  margin: 20px 0;
}

.go-back-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #00a94f; /* Bootstrap primary color for the button */
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.go-back-btn:hover {
  background-color: #11904c; /* Darken button color on hover */
}
`, "",{"version":3,"sources":["webpack://./src/features/default/src/Components/Error/NotFound.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,yBAAyB,EAAE,0BAA0B;AACvD;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc,EAAE,uCAAuC;AACzD;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,yBAAyB,EAAE,2CAA2C;EACtE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB,EAAE,iCAAiC;AAC9D","sourcesContent":["/* NotFound.css */\n.not-found-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 80vh;\n  background-color: #f0f2f5; /* Light grey background */\n}\n\n.not-found-content {\n  text-align: center;\n}\n\n.not-found-content h1 {\n  font-size: 72px;\n  color: #505f79; /* Dark grey color for the '404' text */\n}\n\n.not-found-content h2 {\n  font-size: 24px;\n  color: #505f79;\n}\n\n.not-found-content p {\n  color: #505f79;\n  margin: 20px 0;\n}\n\n.go-back-btn {\n  padding: 10px 20px;\n  font-size: 16px;\n  background-color: #00a94f; /* Bootstrap primary color for the button */\n  border: none;\n  color: white;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.go-back-btn:hover {\n  background-color: #11904c; /* Darken button color on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
